import { request } from '@/util/http'

export const historyFlowList = (processInstanceId) => {
  return request({
    url: '/flow/process/history-flow-list',
    method: 'get',
    params: {
      processInstanceId,
      v: new Date().getTime()
    }
  })
}

export const getTask = (taskId) => {
  return request({
    url: '/flow/work/get-task',
    method: 'get',
    params: {
      taskId
    }
  })
}
